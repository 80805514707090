// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Project from '../components/Project';
import '../css/gallery.scss';

const Projekte = (props) => {
	const client = props.data.allProject.nodes[0];
	const images = {};

	props.data.allImageSharp.edges.forEach(img => {
		images[img.node.fluid.originalName] = img.node.fluid;
	});

	const project = client.projects.find(project => project.slug === props.pageContext.slug);

	const gallery = project.gallery.flatMap((row, index) => {
		return row.map(img => {
			if (img && img.src && images[img.src]) {
				return {
					border: img.border,
					fluid: images[img.src],
				};
			}

			return null;
		});
	});

	return (
		<Project
			name={client.name}
			location={client.location}
			gallery={gallery}
		/>
	);
};

export default Projekte;

export const query = graphql`
	query ($slug: String!) {
		allProject(filter: {projects: {elemMatch: {slug: {eq: $slug}}}}) {
			nodes {
				name
				location
				slug
				projects {
					slug
					gallery {
						src
						border
					}
				}
			}
		}
		allImageSharp(filter: {fluid: {src: {regex: "/project-/"}}}, sort: {fields: fluid___originalName, order: ASC}) {
			edges {
				node {
					id
					fluid(quality: 90, maxWidth: 1080) {
						base64
						srcWebp
						srcSetWebp
						originalImg
						originalName
						presentationWidth
						presentationHeight
						sizes
						src
						srcSet
						aspectRatio
					}
				}
			}
		}
	}
`;
