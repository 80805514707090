import React from 'react';
import SEO from './SEO';
import Image from './Image';
import Back from './Back';
import Layout from './Layout';
import '../css/gallery.scss';

const Project = (props) => (
	<Layout>
		<SEO title={props.name} theme="dark" />
		<h2 className="list__item separator separator--top separator--bottom type--center">
			{props.name}<br />
			{props.location}
		</h2>
		<div className="gallery">
			{props.gallery.map((image, index) => {
				return image ? (
					<div className="gallery__row" key={`gallery-${index}`}>
						<Image index={index} border={image.border} fluid={image.fluid} key={`image-${index}`} />
					</div>
				) : null;
			})}
		</div>
		<Back />
	</Layout>
);

export default Project;
